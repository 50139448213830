.logo {
  height: 40px;
  width: auto;
}

#header-logo {
  margin: 10px;
}

#mainHeader {
  z-index: 8001;
}

.headerPanel {
  height: max-content;
}

.banner p {
  margin: 0;
  /* padding: 5px 0; */;
  font-size: 12px;
}

.userDetails {
  padding: 5%; 
}

li.userDetails {
  cursor: default;
}

li.clickableUserDetails {
  cursor: pointer;
}

li.clickableUserDetails:hover  {
  background: #072655;
}

.adminPageContent {
  margin-left: 10%;
  margin-right: 5%;
}

.pageContent {
  margin-left: 2%;
  margin-right: 5%;
}

.inputText {
  --cds-text-primary: black;
  width: 100%;
  --cds-text-secondary: black;
}


.inputSearch {
  --cds-text-primary: black;
  width: 100%;
  --cds-text-secondary: black;
  --cds-icon-primary: black;
}

.rules {
  display: flex;
  justify-content: space-between;
}

.first-division {
  display: flex;
  flex-direction: column;
  margin: 0 5px 0.5rem 0;
  width: 100%;
}

.second-division {
  padding-top: 5%;
}

button {
  background: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 2px;
  padding: 5px 10px;
}

.add_button {
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  color: rgb(0, 208, 255);
  border: 1px solid rgb(0, 208, 255);
}

.remove-btn {
  color: red;
  border: 1px solid red;
}

.services {
  display: flex;
  justify-content: space-between;
}

.ruleBody {
  border: 0.5px solid #295785;
  border-radius: 5px;
  margin: 2%;
  padding: 0.5%;
}

.gridBoundary {
  border: 0.5px solid #295785;
  border-radius: 5px;
  padding: 1%;
  box-shadow: 4px 2px 4px #d3d3d3; 
  margin-bottom: 1%;
}

.columnBoundary {
  border: 0.5px solid #295785;
  border-radius: 5px;
  margin: 2%;
  padding: 1%;
  width: 120%;
}

.inlineDiv {
  display: flex;
  margin-top: 2%;
  margin-bottom: 3%;
  margin-left: 1%; 
}
.section {
  border: 0.5px solid green;
  border-radius: 3px;
  margin: 0.5%;
  padding: 1%;
}


.errorMessage {
  font-size: 12px;
  color: red;
  width: 150px;
  margin-top: 0.25rem;
}

.errorMessage::before {
  content: " ";
  font-size: 10px;
}

.inlineDivBlock > div {
  display: inline-block;
  margin-right: 1rem;
}

.inlineChildDiv {
  margin-left : 1%
}

.middleAlign {
  height: 100%;
  display: flex;
  align-items: center; 
  justify-content: center;
}

.middleAlignHorizontal {
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.middleAlignVertical {
  height: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.bottomAlign {
  height: 100%;
  display: flex;
  align-items: flex-end;
  /* justify-content: center; */
}

.formInlineDiv {
  /* display: flex;
  flex-wrap: nowrap;
  width: 100%; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2%;
}

.formDiv{
  background-color: #bfc2bc;
}

.resultPageContent {
  margin-left: 1%; 
  margin-right: 0%;
}

.searchLabNumber {
  margin-left: 0%; 
  margin-right: 0%;
  width: 50%;
}

.sampleInfo {
  /* margin-left: 0%;  */
  margin-left: 0%;
  margin-right: 0%;
}

.referralRow {
  /* margin-right: 50%; */
  margin-left: 5%;
  width: 100%;
}

/* .resultValue {
 
  margin-left: 5%;
  width: 100%;
}

.currentResultValue {
 
  margin-left: 5%;
  width: 100%;
} */

.referralReason {
  margin-left: 25%; 
  margin-right: 5%; 
  width: 100%;
}
.institute {
  margin-left: 5%;
  margin-right: 5%;
  width: 100%;
}

/* Results */
.inputText {
  --cds-text-primary: black;
  width: 100%;
  --cds-text-secondary: black;
  /* margin-top: 0.1%; */
}

.inputText2 {
  --cds-text-primary: black;
  width: 380px;
  --cds-text-secondary: black;
  padding-left: 1%;
  /* margin-top: 0.1%; */
}

.inputSelect {
  --cds-text-primary: black;
  width: 100%;
  --cds-text-secondary: black;
}

.inputSelect2 {
  --cds-text-primary: black;
  width: 380px;
  --cds-text-secondary: black;
}

.inputSearch {
  --cds-text-primary: black;
  width: 100%;
  --cds-text-secondary: black;
  --cds-icon-primary: black;
}

.results {
  display: flex;
  justify-content: space-between;
}

.first-division {
  display: flex;
  flex-direction: column;
  margin: 0 5px 0.5rem 0;
  width: 100%;
}

.second-division {
  padding-top: 5%;
}

button {
  background: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 2px;
  padding: 5px 10px;
}

.add_button {
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  color: rgb(0, 208, 255);
  border: 1px solid rgb(0, 208, 255);
}

.remove-btn {
  color: red;
  border: 1px solid red;
}

.services {
  display: flex;
  justify-content: space-between;
}

.resultBody {
  border: 0.5px solid #295785;
  border-radius: 5px;
  margin: 2%;
  padding: 0.5%;
}

.inlineDiv {
  display: flex;
  margin-top: 2%;
  margin-bottom: 3%;
  margin-left: 1%;
}

.section {
  border: 0.5px solid green;
  border-radius: 3px;
  margin: 0.5%;
  padding: 1%;
}

.errorMessage {
  font-size: 12px;
  color: red;
  width: 150px;
  margin-top: 0.25rem;
}

.errorMessage::before {
  content: " ";
  font-size: 10px;
}

.requiredFieldIndicator{
  color: red;
}
.toastDisplay{
  position: fixed; /*or fixed*/
  top: 47px;
  right: 0px;
  z-index: 10;
}

.selectSampleType {
  --cds-text-primary: black;
  width: 200px;
  --cds-text-secondary: black;
    margin-bottom: 20px;
}

.exampleDiv {
  background-color: #f1f1f1;
  overflow: auto;
  text-align: justify;
  padding: 0.6875rem 1rem;
}

.error {
  font-size: 12px; 
	  color: red;
    margin-bottom: 0.25rem;
	  /* width: 150px; */
	  /* 
	  &:before {
	    content: " ";
	    font-size: 10px;
	  } */
}

.second-row {
  padding-top: 3%;
  padding-left: 3%;
}

.first-row {
  max-width : 500px ;
}
.searchResultsBtn{
  margin: 10px !important;
}
.advancedSearchResultsBtn {
  margin: 10px !important;
}

.cds--date-picker__input{
  max-width: 200px;
}

.hidden {
  visibility: hidden;
}

.font1 {
  font-size: 14px;
  color: #201f1f;
}

.requiredlabel {
  color: #FF0000;
}

.my-custom-table {
  margin: 20px;
}

.my-custom-table table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
}

.my-custom-table th, .my-custom-table td {
  padding: 8px;
  text-align: left;
}

.my-custom-table th {
  background-color: #f4f4f4;
}

.my-custom-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}