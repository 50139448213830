/* src/components/Dashboard.css */
.dashboard-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 50px;
    padding: 50px;
}

.dashboard-view {
    padding: 50px;
}
.dashboard-tile {
    padding: 20px;
    border-radius: 3px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f4f4f4;
}


.tile-title {
    margin: 0;
    font-size: 1.2rem;
}

.tile-subtitle {
    margin-top: 5px;
    color: #666;
}

.tile-value {
    margin-top: 10px;
    font-size: 1.5rem;
    font-weight: bold;
}

.tile-icon {
    position: absolute;
    top: 10px;
    right: 10px;
}

.icon-wrapper {
    transition: border-color 0.3s; /* Add a smooth transition effect for the border */
  }
  
  .icon-wrapper:hover {
    border: 1px solid #78a9ff; /* Add a blue border on hover */
    cursor: pointer;
  }

  .tile-title-view {
    margin: 0;
    font-size: 1.6rem;
  }

.tile-subtitle-view {
    margin-top: 5px;
    color: #666;
}

.tile-value-view {
    margin-top: 10px;
    font-size: 1.9rem;
    font-weight: bold;
}

