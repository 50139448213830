tr.evenRow {
	background-color: #C8DADA;
}

tr.evenRowHead {
	background-color: #B8CACA;
	padding: 20px;
}
tr.oddRow {
	background-color: #F0F3F3;
}

tr.oddRowHead {
	background-color: #E0E3E3;
}

.contentHeader1{
	margin-top: 5px;
	font-weight: bold;
	font-size: 23px;
    line-height: normal;
    color: #235689;
    background-color: #DADDDD;
    width: 100%;
    border: none;
}

.contentHeader2{
	margin-top: 2px;
    border: none;
	font-size: 17px;
    background-color: #E0E3E3;
    line-height: normal;
}