
.dashboard-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 50px;
}

.dashboard-tile {
    border-radius: 3px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f4f4f4;
}

.tile-title {
    margin: 0;
    font-size: 1.2rem;
}

.tile-subtitle {
    margin-top: 5px;
    color: #666;
}

.tile-value {
    margin-top: 10px;
    font-size: 1.5rem;
    font-weight: bold;
}


.patient-header {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  margin-top: 20px;
}

.patient-name {
  font-size: 18px;
  margin-bottom: 5px;
}

.patient-dob {
  font-size: 16px;
  color: #666;
  margin-bottom: 5px;
}

.patient-id {
  font-size: 14px;
  color: #888;
}

.file-container {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 20px;
  width: 500px;
  /* display: flex;
  flex-direction: column; */
  align-items: flex-start;
  justify-content: center;
  /* text-align: left; */
  margin-top: 20px;
}
